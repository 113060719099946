<template>
  <div>
    <v-form>
      <p class="mb-5">Match each of the terms below to their definitions.</p>

      <v-simple-table style="max-width: 413px">
        <tr v-for="question in questions" :key="question.letter">
          <td><stemble-latex :content="question.text" /></td>
          <td>
            <v-select
              :key="options.value"
              v-model="inputs[question.inputName]"
              class="d-sm-inline-block my-n3"
              :items="optionsShuffled"
              item-text="text"
              item-value="value"
              style="width: 250px"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'Chem1LD_Q3S1_Question8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
      questions: [
        {text: 'a) Sensitizer', inputName: 'input1'},
        {text: 'b) Asphyxiant', inputName: 'input2'},
        {text: 'c) Teratogen', inputName: 'input3'},
        {text: 'd) Carcinogen', inputName: 'input4'},
        {text: 'e) Organ toxicant', inputName: 'input5'},
        {text: 'f) Neurotoxin', inputName: 'input6'},
        {text: 'g) Poison', inputName: 'input7'},
      ],
      options: [
        {text: 'Acetone', value: 'acetone'},
        {text: 'Acrylonitrile', value: 'acrylonitrile'},
        {text: 'Benzene', value: 'benzene'},
        {text: 'Carbon monoxide', value: 'carbonMonoxide'},
        {text: 'Cyanide', value: 'cyanide'},
        {text: 'Ethanol', value: 'ethanol'},
        {text: 'Formaldehyde', value: 'formaldehyde'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
